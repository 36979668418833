<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Product Promos</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark to="/promoCodes/detail" class="mb-2"
        >Ekle</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="all"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deleteItem(item)">delete</v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import router from "@/router";

export default {
  data() {
    return {
      all: [],
      headers: [
        {
          text: "id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "chip", value: "chip" },
        { text: "currentCount", value: "currentCount" },
        { text: "expireCount", value: "expireCount" },
        { text: "expireTime", value: "expireTime" },
        { text: "gold", value: "gold" },
        { text: "ownerUserId", value: "ownerUserId" },
        { text: "targetUserId", value: "targetUserId" },
        { text: "vipPoint", value: "vipPoint" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async deleteItem(item) {
      await this.$store.dispatch("productPromos/delete", item.id);
      await this.initialize();
    },
    editItem(item) {
      router.push("/products/detail/" + item.id);
    },
    async initialize() {
      await this.$store.dispatch("productPromos/getAll");
      this.all = this.$store.state.productPromos.all;
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
